<template>
  <div class="animated fadeIn container">
    <div class="card p-4" style="width:100%;margin:0 auto;max-width: 600px">
      <h2>헬로문자 충전</h2>
      <p class="pt-2 color-flatwhite">가상계좌 입금 후 자동으로 충전되며, 10분 정도 소요됩니다</p>
      <p class="text-center color-skyblue my-4" style="font-size:30px">{{ payment.amount | numberFormat }}원</p>
      <p class="text-color color-skyblue my-2">
        {{ payment.vbankName }} / {{ payment.vbankNum }} (예금주:{{ payment.vbankHolder }}) <br />입금기한:
        {{ parsedDate(payment.vbankDate) }}
      </p>

      <b-btn variant="primary" class="mt-4" @click.prevent="clickConfirm">확인</b-btn>
    </div>
  </div>
</template>
<script>
import PaymentService from '@/services/PaymentService'
import moment from 'moment-timezone'

export default {
  data() {
    return {
      payment: {},
    }
  },
  mounted() {
    this.getPayment()
  },
  methods: {
    async getPayment() {
      const response = await PaymentService.get(this.$route.params.id)
      console.log(response)
      if (response.status == 200) {
        this.payment = response.data.payment
      }
    },
    parsedDate(value) {
      return moment.unix(value).format('YYYY. MM. DD LTS')
    },
    clickConfirm() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped>
.button-container {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
}

.button-container > div {
  display: flex;
  align-items: center;
}

.button-list {
  list-style: none;
  width: 100%;
  line-height: 20px;
}

.button-list > li {
  float: left;
  color: white;
  position: relative;
  width: 23%;
  margin-left: 2%;
  padding: 4px 0;
  margin: 3px 1%;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  letter-spacing: -0.5px;
  height: 28px;
  overflow: auto;
  min-width: 70px;
}

.deposit-btn {
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
}
</style>
